import { StyleSheet } from 'react-native'

import { Table, TBody, TR, THead } from '@expo/html-elements'

import { dsv } from '../styles/defaults'
import { TH, TD, H4, Text } from './TextComponents'
import { AktenIdentifier } from '@dpa/common/dist'

import { TriggeredNotificationsSchema, TriggeredNotifications } from '@dpa/common/dist'
import { trpc } from '../../App'

import { useMemo } from 'react';

const tableStyle = StyleSheet.create({
  td: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 140,
  },
  th: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
    minWidth: 140,
  },
  cellBorder: {
    borderBottomWidth: 1,
    borderBottomColor: dsv.colors.connectGrau7,
    borderBottomStyle: 'solid',
    borderSpacing: 10,
  },
})

interface ProjectNotificationLogProps {
  identifier: AktenIdentifier
}

function useNotificationList({ identifier }: ProjectNotificationLogProps) {
  const response = trpc.akte.projectNotificationList.useQuery({ id: identifier.id })
  const parsedResponse = TriggeredNotificationsSchema.array().safeParse(response.data)

  if (parsedResponse.success) {
    return {
      projectNotificationList: parsedResponse.data,
      isLoading: false,
    }
  }
  return {
    projectNotificationList: undefined,
    isLoading: response.isLoading,
  }
}



export function ProjectNotificationLog({ identifier }: ProjectNotificationLogProps) {
  const { projectNotificationList } = useNotificationList({ identifier })

  const projectNotificationLogItems = useMemo(
    () => {
      if (projectNotificationList != null && projectNotificationList.length > 0) {
        return projectNotificationList.map((projectNotificationItem) => { return <ProjectNotificationLogItem key={projectNotificationItem.id} item={projectNotificationItem} /> })
      } else {
        return <TR><TD colSpan={4}>Keine Einträge gefunden</TD></TR>
      }
    },
    [projectNotificationList]
  )

  return (
    <>
      <Table
        style={{
          backgroundColor: dsv.colors.connectGrau7,
          borderCollapse: 'collapse',
          marginVertical: 12,
          width: '100%',
        }}
      >
        <THead style={{ backgroundColor: dsv.colors.GpJouleGelb }}>
          <TR style={tableStyle.cellBorder}>
            <TH style={[tableStyle.th]}>
              <H4>Abgesendet am</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Inhalt</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Adressaten</H4>
            </TH>
          </TR>
        </THead>
        <TBody>
          {projectNotificationLogItems}
        </TBody>
      </Table>
    </>
  )
}

export interface NotificationLogItemProps {
  item: TriggeredNotifications
}

export function ProjectNotificationLogItem({ item }: NotificationLogItemProps) {
  return (
    <TR style={tableStyle.cellBorder}>
      <TD style={tableStyle.td}>
        {`${item.creationData.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} ${item.creationData.toLocaleDateString()}`}
      </TD>
      <TD style={tableStyle.td}>
        <H4><div dangerouslySetInnerHTML={{__html: item.subject}}></div></H4>
        <Text>{item.bodyTxt}</Text>
      </TD>
      <TD style={tableStyle.td}>
        {JSON.parse(item.target).join(', ')}
      </TD>
    </TR>
  )
}
