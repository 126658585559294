import { Div } from '@expo/html-elements'
import { Text } from '../../../../../components/TextComponents'

import { dsv } from '../../../../../styles/defaults'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'
import { HandoverEventData } from '.'
import { useTranslation } from 'react-i18next'
import { formatDateTime } from './utils'

export interface UebergabeRowProps {
  handovers: Exclude<ProcessUebergabeStepType, "SuW2Running">[]
  step: "preRequest" | "preRequestRejected" | "request" | "approved" | "rejected" | "returnFrom"
  data: HandoverEventData
}
export function UebergabeRow({ handovers, step, data }: UebergabeRowProps) {
  const {t} = useTranslation()
  return (
    <Div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        borderColor: dsv.colors.connectGrau30,
        borderStyle: 'solid',
        borderBottomWidth: 1,
        marginVertical: 5,
      }}
    >
      <Div style={{ width: '15%' }}>
        <Text style={{ color: dsv.colors.black }}>{t(`uebergabeAction_${step}`)}</Text>
      </Div>
      {handovers.map((handover) => 
        <Div key={handover} style={{ width: `${85/handovers.length}%` }}>
          <Text>
            {
              data[handover] == undefined || data[handover][step] === undefined 
                ? "-/-" 
                : data[handover][step] === null 
                  ? "N.a." 
                  : `${formatDateTime(data[handover][step].at) }`
            }
          </Text>
        </Div>
      )}
    </Div>
  )
}
