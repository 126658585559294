import { Section } from '@expo/html-elements'
import { Card, CardBody, CardHeader } from '../../components/Card'
import { Col, Row } from '../../components/Row'
import { UserNotificationsLog } from '../../components/UserNotificationsLog'

export function Notifications() {
  return (
    <Section>
      <Row>
        <Col cols={12}>
          <Card>
            <CardHeader>Meine Benachrichtigungen</CardHeader>
            <CardBody>
              <Section>
                <UserNotificationsLog />
              </Section>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  )
}
