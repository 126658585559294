import { z } from 'zod'
import { SetStateAction, useCallback, useMemo, useState } from 'react'
import { Section, TBody, Table, THead, TR } from '@expo/html-elements'
import { useTranslation } from 'react-i18next'
import { H2, TD, TH, Text } from '../../components/TextComponents'

import { trpc } from '../../../App'
import { View } from 'react-native'
import { Card, CardBody, CardHeader } from '../../components/Card'
import { Col, Row } from '../../components/Row'
import { dsv } from '../../styles/defaults'

import { useNewAutoForm } from '../../components/form/AutoformV2'
import { RequiredFieldsForm, RequiredFieldsFormFields } from '../../forms/requiredFields'
import { addPrefix } from '../../locales/translationKeys'

import { LoadingScreen } from '../../components/LoadingScreen'

import { ProcessTypeSchema } from '@dpa/common/dist'
import { InputSelect } from '../../components/form/AutoFormInputFields/InputSelect'
import { useAutoFormV2JSXGroups } from '../../hooks/form/useAutoFormV2JSXGroups'
import { datenblattFromSchema } from '../../forms/datenblattFromSchema'
import { useAuthContext } from '../../hooks/context/useAuthContext'
// TODO: fixme
const projectArten = [
  'Elektifizierung_AC',
  'Elektifizierung_DC',
  'komplexe_DC_inclIBN',
  'standart_DC_inclIBN',
  'auslieferung_DC',
  'ac_inc_config',
] as const

export function SetRequiredFields() {
  const { t } = useTranslation()

  const { authCtx } = useAuthContext()
  const isAllowed = useMemo(() => authCtx?.roles.includes('Admin'), [authCtx?.roles])

  const [processType, setProcessType] = useState<z.infer<typeof ProcessTypeSchema>>(projectArten[0])

  const processGates = trpc.processGate.byProcessType.useQuery({ processType: processType })

  const { mutate } = trpc.processGate.update.useMutation()

  const setUebergabe_Vertrieb2PE = useCallback(
    (field: string, value: string) =>
      mutate({
        field, //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'Vertrieb2PE',
      }),
    [processType, mutate]
  )

  const setUebergabe_PE2Vertrieb = useCallback(
    (field: string, value: string) =>
      mutate({
        field, //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'PE2Vertrieb',
      }),
    [processType, mutate]
  )

  const setUebergabe_Vertrieb2ENG = useCallback(
    (field: string, value: string) =>
      mutate({
        field, //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'Vertrieb2ENG',
      }),
    [processType, mutate]
  )

  const setUebergabe_ENG2Vertrieb = useCallback(
    (field: string, value: string) =>
      mutate({
        field, //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'ENG2Vertrieb',
      }),
    [processType, mutate]
  )

  const setUebergabe_Vertrieb2Projektleitung = useCallback(
    (field: string, value: string) =>
      mutate({
        field, //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'Vertrieb2Projektleitung',
      }),
    [processType, mutate]
  )

  const setUebergabe_Projektleitung2SuW = useCallback(
    (field: string, value: string) =>
      mutate({
        field, //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'Projektleitung2SuW',
      }),
    [processType, mutate]
  )
  const setUebergabe_SuW2Running = useCallback(
    (field: string, value: string) =>
      mutate({
        field, //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'SuW2Running',
      }),
    [processType, mutate]
  )




  const { inputFieldEntries: formEntries_Vertrieb2PE, hasData: form_Vertrieb2PE_HasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    setUebergabe_Vertrieb2PE,
    processGates?.data?.Vertrieb2PE,
    true
  )
  const group_Vertrieb2PE = useAutoFormV2JSXGroups(formEntries_Vertrieb2PE, datenblattFromSchema)

  const { inputFieldEntries: formEntries_PE2Vertrieb, hasData: form_PE2Vertrieb_HasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    setUebergabe_PE2Vertrieb,
    processGates?.data?.PE2Vertrieb,
    true
  )
  const group_PE2Vertrieb = useAutoFormV2JSXGroups(formEntries_PE2Vertrieb, datenblattFromSchema)

  const { inputFieldEntries: formEntries_Vertrieb2ENG, hasData: form_Vertrieb2ENG_HasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    setUebergabe_Vertrieb2ENG,
    processGates?.data?.Vertrieb2ENG,
    true
  )
  const group_Vertrieb2ENG = useAutoFormV2JSXGroups(formEntries_Vertrieb2ENG, datenblattFromSchema)

  const { inputFieldEntries: formEntries_ENG2Vertrieb, hasData: form_ENG2Vertrieb_HasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    setUebergabe_ENG2Vertrieb,
    processGates?.data?.ENG2Vertrieb,
    true
  )
  const group_ENG2Vertrieb = useAutoFormV2JSXGroups(formEntries_ENG2Vertrieb, datenblattFromSchema)

  const { inputFieldEntries: formEntries_Vertrieb2Projektleitung, hasData: form_Vertrieb2Projektleitung_HasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    setUebergabe_Vertrieb2Projektleitung,
    processGates?.data?.Vertrieb2Projektleitung,
    true
  )
  const group_Vertrieb2Projektleitung = useAutoFormV2JSXGroups(formEntries_Vertrieb2Projektleitung, datenblattFromSchema)

  const { inputFieldEntries: formEntries_Projektleitung2SuW, hasData: form_Projektleitung2Su_HasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    setUebergabe_Projektleitung2SuW,
    processGates?.data?.Projektleitung2SuW,
    true
  )
  const group_Projektleitung2Su = useAutoFormV2JSXGroups(formEntries_Projektleitung2SuW, datenblattFromSchema)


  
  const { inputFieldEntries: formEntries_SuW2Running, hasData: form_SuW2Running_HasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    setUebergabe_SuW2Running,
    processGates?.data?.Projektleitung2SuW,
    true
  )
  const group_SuW2Running = useAutoFormV2JSXGroups(formEntries_SuW2Running, datenblattFromSchema)


  return (
    <Section>
      <Row>
        <Col cols={12}>
          <Card>
            <CardHeader>
              <Text style={dsv.text}>Uebergabe Felder Festlegen</Text>
            </CardHeader>
            <CardBody customStyle={{ minHeight: 300 }}>
              <InputSelect
                options={projectArten}
                strValue={processType}
                setStrValue={setProcessType as SetStateAction<string>}
                saveStrValue={setProcessType as SetStateAction<string>}
              />
              {processGates.isLoading ||
              !(
                form_Vertrieb2PE_HasData &&
                form_PE2Vertrieb_HasData &&
                form_Vertrieb2ENG_HasData &&
                form_ENG2Vertrieb_HasData &&
                form_Vertrieb2Projektleitung_HasData &&
                form_Projektleitung2Su_HasData &&
                form_SuW2Running_HasData
              ) ? (
                  <LoadingScreen active />
                ) : !isAllowed ? (
                  <View>
                    <Text>Fehlende Benutzer Berechtigungen, wenden sie sich an einen Administrator</Text>
                  </View>
                ) : (
                  <View>
                    {group_Vertrieb2PE.map(([groupName, groupEntries], groupIdx) => (
                      <Table
                        key={groupName}
                        style={{
                        // TODO: Fix styling for mobile app
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                          textAlign: 'left',
                          marginBottom: '20px',
                        }}
                      >
                        <THead>
                          <TR>
                            <TH style={{ width: '20%' }}>
                              <H2 style={{ color: dsv.colors.connectGrau7 }}>{groupName}</H2>
                            </TH>
                            <TH
                              style={{
                                width: '13%',
                                color: dsv.colors.connectGrau7,
                              }}
                            >
                              Vertrieb2PE
                            </TH>
                            <TH
                              style={{
                                width: '13%',
                                color: dsv.colors.connectGrau7,
                              }}
                            >
                              PE2Vertrieb
                            </TH>
                            <TH
                              style={{
                                width: '13%',
                                color: dsv.colors.connectGrau7,
                              }}
                            >
                              Vertrieb2ENG
                            </TH>
                            <TH
                              style={{
                                width: '13%',
                                color: dsv.colors.connectGrau7,
                              }}
                            >
                              ENG2Vertrieb
                            </TH>
                            <TH
                              style={{
                                width: '13%',
                                color: dsv.colors.connectGrau7,
                              }}
                            >
                              Vertrieb2Projektleitung
                            </TH>
                            <TH
                              style={{
                                width: '13%',
                                color: dsv.colors.connectGrau7,
                              }}
                            >
                              Projektleitung2SuW
                            </TH>
                            <TH
                              style={{
                                width: '13%',
                                color: dsv.colors.connectGrau7,
                              }}
                            >
                              SuW2Running
                            </TH>
                          </TR>
                        </THead>
                        <TBody>
                          {groupEntries.map(([fieldName, inputField], fieldIdx) => {
                            return (
                              <TR
                                key={fieldName}
                                style={{
                                  backgroundColor: fieldIdx % 2 === 0 ? dsv.colors.base : dsv.colors.base2,
                                }}
                              >
                                <TH>{t(addPrefix('labels', fieldName))}</TH>
                                <TD>{inputField}</TD>
                                <TD>{group_PE2Vertrieb[groupIdx][1][fieldIdx][1]}</TD>
                                <TD>{group_Vertrieb2ENG[groupIdx][1][fieldIdx][1]}</TD>
                                <TD>{group_ENG2Vertrieb[groupIdx][1][fieldIdx][1]}</TD>
                                <TD>{group_Vertrieb2Projektleitung[groupIdx][1][fieldIdx][1]}</TD>
                                <TD>{group_Projektleitung2Su[groupIdx][1][fieldIdx][1]}</TD>
                                <TD>{group_SuW2Running[groupIdx][1][fieldIdx][1]}</TD>
                              </TR>
                            )
                          })}
                        </TBody>
                      </Table>
                    ))}
                  </View>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  )
}
