import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useTranslation } from 'react-i18next'

import { textWithPlaceHolder } from '../../../../utils/text'

import { SubCard } from '../../../../components/SubCard'
import { InfoItem } from './InfoItem'
import { useMemo } from 'react'
import { toCurrencyString } from '../../../../utils/currency'
import { useFinanceData } from '../../../../hooks/useFinanceData'
import { toPercentString } from '../../../../utils/percent'
import { dateInDdMmYyyy } from '../../../../utils/date'


export function SubCardDetails() {
  const akte = useAkteContext()
  const { t } = useTranslation()

  const financeData = useFinanceData(akte.identifier)

  const {
    marge,
    gesAmount,
  } = useMemo(
    ()=>{
      if(financeData.isLoading || financeData.data == undefined){
        return {
          marge: "Wird geladen ...",
          gesAmount: "Wird geladen ...",
        }
      }
      if(financeData.data.stats == undefined){
        return {
          marge: "unbekannt",
          gesAmount: "unbekannt",
        }
      }else{
        return {
          marge: toPercentString(financeData.data.stats.marge),
          gesAmount: toCurrencyString(financeData.data.stats.gesAmount),
        }
      }
    },
    [financeData.isLoading, financeData.data]
  )
  
  const isLoading = akte.isLoading
  if (isLoading) {
    return <></>
  }
  return (
    <SubCard width="32%" title="Details">
      <InfoItem name="Projektnummer" value={textWithPlaceHolder(akte.serverState?.PDB_Projektnummer)} />
      <InfoItem
        name="Projekttitel"
        value={textWithPlaceHolder(akte.serverState?.UE_Kundendaten_Projekttitel)}
        darkerLine={true}
      />
      <InfoItem name="Projekttyp" value={textWithPlaceHolder(akte.serverState?.PDB_Projekttyp)} />
      <InfoItem name="Projektart" value={textWithPlaceHolder(akte.serverState?.PDB_Projektart, t)} darkerLine={true} />
      <InfoItem
        name="Erstellt am"
        value={textWithPlaceHolder(dateInDdMmYyyy(akte.serverState?.creationData), t)}
      />
      <InfoItem name="Marge" value={marge} darkerLine={true} />
      <InfoItem name="Gesamtumsatz" value={gesAmount} />
    </SubCard>
  )
}
