import { H4 } from '../../../../components/TextComponents'
import { View, StyleSheet } from 'react-native'
import { Link } from '../../../../utils/routing'
import { dsv } from '../../../../styles/defaults'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthContext } from '../../../../hooks/context/useAuthContext'
import { useUebergabenToDisplay } from '../../../../hooks/roleBasedDisplay/useUebergabenToDisplay'
import { useTranslation } from 'react-i18next'

interface Link {
  title: string
  to: string
  disabled?: boolean
}

interface TabsProps {
  links: Link[]
  defaultLink?: Link
}

const linkStyle = StyleSheet.create({
  current: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    shadowRadius: 8,
    shadowOpacity: 0.15,
    height: 52,
    paddingHorizontal: 24,
    zIndex: 10,
  },
  default: {
    paddingHorizontal: 24,
    zIndex: 0,
  },
})

export function ProjectTabs() {

  const auth = useAuthContext()

  const { t } = useTranslation()

  const toDisplay = useUebergabenToDisplay(auth.authCtx?.roles)

  const links: Array<Link> = useMemo(() => {
    const navLinks = []

    navLinks.push({
      title: 'Status & Übersicht',
      to: './state',
    })
    navLinks.push({
      title: 'Produkte',
      to: './products',
    })
    toDisplay.forEach(uebergabeName => {
      navLinks.push({
        title: t(`tabTitle_${uebergabeName}`),
        to: `./${uebergabeName.toLowerCase()}`,
      })
    })
    console.log("toDisplay", toDisplay)
    
    navLinks.push({
      title: 'Datenblatt',
      to: './datasheet',
    })

    if (
      auth.authCtx?.roles.includes('Admin') ||
      auth.authCtx?.roles.includes('ServiceUndWartung') ||  // TODO: checken
      auth.authCtx?.roles.includes('ProjektSupport')        // TODO: checken
    ) {
      navLinks.push(
        {
          title: 'Backend Auftrag',
          to: './orderBackend',
        },
        {
          title: 'Konfig. Auftrag',
          to: './orderConfig',
        }
      )
    }

    return navLinks
  }, [auth.authCtx?.roles, toDisplay, t])

  const defaultLink = useMemo(() => links[0], [links])

  const [activeLinkIndex, setActiveLinkIndex] = useState<number | undefined>(undefined)

  useEffect(
    () => {
      let index = links.findIndex((link) => {
        const relevantPath = link.to.replace('./', '/')
        return window.location.pathname.endsWith(relevantPath) || window.location.pathname.includes(relevantPath + '/')
      })

      if (defaultLink && index === -1) {
        index = links.findIndex((link) => link == defaultLink)
      }

      setActiveLinkIndex(index)
    },
    [links, defaultLink]
  )

  const updateActiveLinkIndex = useCallback((index: number) => setActiveLinkIndex(index), [setActiveLinkIndex])

  const linkElements = links.map((link, linkIndex) => (
    <Link
      onClick={link.disabled ? undefined : () => updateActiveLinkIndex(linkIndex)}
      key={link.title}
      to={link.to}
      disabled={link.disabled /*//TODO: not working ... */}
      style={{ textDecoration: 'none', ...dsv.text }}
    >
      <View
        style={[
          {
            borderTopLeftRadius: linkIndex === 0 ? 8 : 0,
            borderTopRightRadius: linkIndex + 1 === links.length ? 8 : 0,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: dsv.colors.base3,
            backgroundColor: link.disabled ? dsv.colors.connectGrau50 : dsv.colors.connectGrau,
            opacity: link.disabled ? 0.5 : undefined,
          },
          activeLinkIndex === linkIndex ? linkStyle.current : linkStyle.default,
        ]}
      >
        <H4
          style={[
            {
              marginVertical: 12,
              color: dsv.colors.connectGrau7,
            },
            activeLinkIndex === linkIndex
              ? {
                borderStyle: 'solid',
                borderBottomColor: dsv.colors.GpJouleGelb,
                borderBottomWidth: 2,
              }
              : {},
          ]}
        >
          {link.title}
        </H4>
      </View>
    </Link>
  ))

  return (
    <View
      style={{
        flexDirection: 'row',
        paddingHorizontal: 16,
        paddingTop: 8,
        alignItems: 'flex-end',
        overflow: 'hidden',
      }}
    >
      {linkElements}
    </View>
  )
}
