import { StyleSheet } from 'react-native'
import { Table, TBody, TR, THead } from '@expo/html-elements'
import { H4, TD, TH } from './TextComponents'

import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { calculateTranslationKeys } from './form/Autoform'
import { dsv } from '../styles/defaults'
import { CompressedGenericChangeEventWithoutPassWithRelations } from '../hooks/audit/useCompressedAkteAuditLog'
import { dateInDdMmYyyy } from '../utils/date'

export interface ChangeLogEntryProps {
  entry: CompressedGenericChangeEventWithoutPassWithRelations
}
const tableStyle = StyleSheet.create({
  td: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 16,
    paddingTop: 8,
    paddingBottom: 8,
    color: dsv.colors.black,
  },
  th: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  cellBorder: {
    borderBottomWidth: 2,
    borderBottomColor: dsv.colors.connectGrau50,
    borderBottomStyle: 'solid',
  },
})

export function ChangeLogEntry({ entry }: ChangeLogEntryProps) {
  const { t } = useTranslation()
  const changedFields = useMemo(
    () =>
      entry.GenericFieldChanges.map((change) => ({
        labelKey: calculateTranslationKeys(change.field).labelTranslationKey,
        change,
      })),
    [entry]
  )

  return (
    <TR style={tableStyle.cellBorder}>
      <TD style={tableStyle.td}>
        {`
        ${entry.changeData.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} 
        ${dateInDdMmYyyy(entry.changeData)} 
        `}
        <br />
        {entry.changeDataEnd &&
          ` -
        ${entry.changeDataEnd?.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} 
        ${dateInDdMmYyyy(entry.changeDataEnd)} 
        `}
      </TD>
      <TD style={tableStyle.td}>{entry.author.name}</TD>
      <TD style={tableStyle.td}>
        <Table style={{ width: '100%', backgroundColor: dsv.colors.connectGrau7, borderCollapse: 'collapse' }}>
          <THead style={{ backgroundColor: dsv.colors.connectGrau30, color: dsv.colors.connectGrau }}>
            <TR>
              <TH style={tableStyle.th}>
                <H4>Feldname</H4>
              </TH>
              <TH style={tableStyle.th}>
                <H4>Vorher</H4>
              </TH>
              <TH style={tableStyle.th}>
                <H4>Nachher</H4>
              </TH>
            </TR>
          </THead>
          <TBody>
            {changedFields.map(({ labelKey, change }, index) => (
              <TR
                style={[index !== changedFields.length - 1 && tableStyle.cellBorder, tableStyle.th]}
                key={labelKey as string}
              >
                <TD style={[tableStyle.td, { width: '32%' }]}>{t(labelKey as string)}</TD>
                <TD style={[tableStyle.td, { width: '32%' }]}>{change.oldValue}</TD>
                <TD style={[tableStyle.td, { width: '32%' }]}>{change.newValue}</TD>
              </TR>
            ))}
          </TBody>
        </Table>
      </TD>
    </TR>
  )
}
