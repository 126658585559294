import { useEffect, useState } from 'react'

import { AkteCtx } from '../../../../hooks/context/useAkteContext'
import { applyGateConditions, CalcAkte, ProcessGate } from '@dpa/common/dist'
import { useProcessGateContext } from '../../../../hooks/context/useProcessGateContext'
import { objDeltaEntries } from '../../../../hooks/useObjDelta'
import { akteDisplayConditions } from '../akteDisplayConditions'
import { ProcessUebergabeStepType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/ProcessUebergabeStepSchema'

export function useCurrentProcessGate(akte: AkteCtx, currentGate: ProcessUebergabeStepType) {
  const [processGate, setProcessGate] = useState<ProcessGate<CalcAkte> | undefined>(undefined)
  const processGateCtx = useProcessGateContext()
  
  useEffect(() => {
    if (
      akte?.localState == undefined ||
      akte.processType == undefined ||
      processGateCtx.isLoading ||
      processGateCtx.processGates == undefined ||
      processGateCtx.processGates[akte.processType] == undefined
    ) {
      return undefined
    }else{
      const gate = processGateCtx.processGates[akte.processType][currentGate]!
      console.log('akte.processType, currentGate, gate', akte.processType, currentGate, gate)
      const modifiedGate = applyGateConditions(gate, akte.localState, akteDisplayConditions)
      if (objDeltaEntries(modifiedGate, processGate).some(([_, changed]) => changed)) {
        setProcessGate(modifiedGate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [akte.localState, currentGate, processGateCtx.isLoading, processGateCtx.processGates])
  // [akte.localState])
  return processGate
}
