import { useAkteContext } from '../../../../hooks/context/useAkteContext'

import { textWithPlaceHolder, userWithPlaceHolder } from '../../../../utils/text'

import { SubCard } from '../../../../components/SubCard'
import { InfoItem } from './InfoItem'
import { useUserListContext } from '../../../../hooks/context/useUserListContext'

export function SubCardAnsprechpartner() {
  const akte = useAkteContext()

  const { isLoading: usersIsLoading, userById } = useUserListContext()

  const isLoading = akte.isLoading || usersIsLoading
  if (isLoading) {
    return <></>
  }
  return (
    <SubCard width="32%" title="Interne Ansprechpersonen">
      <InfoItem 
        name={'Engineering'}
        value={userWithPlaceHolder(akte.serverState?.PDB_ProjektiererId, userById)}
        largerLeft={true}
      />
      <InfoItem
        name={'Vert.Außen.'}
        value={userWithPlaceHolder(akte.serverState?.PDB_VertriebsmitarbeiterId, userById)}
        darkerLine={true}
        largerLeft={true}
      />
      <InfoItem
        name={'Vert.Innen.'}
        value={userWithPlaceHolder(akte.serverState?.PDB_InnendienstmitarbeiterId, userById)}
        darkerLine={true}
        largerLeft={true}
      />
      <InfoItem
        name={'SCM'}
        value={textWithPlaceHolder(akte.serverState?.PDB_Ansprechpartner_Projektsupport)}
        largerLeft={true}
      />
      <InfoItem
        name={'PMO'}
        value={userWithPlaceHolder(akte.serverState?.PDB_Projektleiter_PMOId, userById)}
        largerLeft={true}  
      />
      <InfoItem
        name={'TBF'}
        value={userWithPlaceHolder(akte.serverState?.PDB_Ansprechpartner_TBFId, userById)}
        largerLeft={true}
      />
    </SubCard>
  )
}
